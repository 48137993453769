import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "ui-integration"
    }}>{`UI Integration`}</h1>
    <p>{`Congratz on making it this far! You know the most valuable pieces of serverless and how to use them.`}</p>
    <p>{`It's okay if you feel a little shakey about everything you've learned so far. This is where you put it to the test and bring it all together.`}</p>
    <p>{`You're going to build a shared click counter.`}</p>
    <p>{`Sounds silly, I know, but it's a great way to test your skills. This is how likes work on twitter, how medium's +1 works, reddit upvotes, etc. At the core, it's all just counting clicks.`}</p>
    <h2 {...{
      "id": "distributed-click-counter"
    }}>{`Distributed click counter`}</h2>
    <p>{`DynamoDB stores data in tables. Each works as its own database and you can have as many as you want.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Take your code from exercise-6 and add 2 new methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`upvote`}</inlineCode>{` which adds a new vote to the database`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`countVotes`}</inlineCode>{` which returns the current number`}</li>
    </ul>
    <p>{`Make sure they're at `}<inlineCode parentName="p">{`<base url>/upvote/<identifier>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<base url>/countvotes/<identifier>`}</inlineCode>{`. POST request for upvoting, GET for counting.`}</p>
    <p>{`You can model the data as a single counting integer. That makes it easier, but you might run into votes overwriting each other. You also won't know when they happened.`}</p>
    <p>{`Another approach is to model your data as a table of votes with a timestamp each.`}</p>
    <p>{`In both cases you'll need some `}<inlineCode parentName="p">{`identifier`}</inlineCode>{` for what's being voted upon.`}</p>
    <p>{`Here's a CodeSandbox you can use for the UI:`}</p>
    <p><iframe parentName="p" {...{
        "src": "https://codesandbox.io/embed/serverless-workshop-click-counter-eysby",
        "style": {
          "width": "100%",
          "height": "500px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        },
        "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
        "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      }}></iframe></p>
    <p>{`It uses React Query and lets you specify your API's domain. You get that from deploying your cloud functions.`}</p>
    <p>{`You can build your own using your favorite way to run API requests.`}</p>
    <p><strong parentName="p">{`Tips`}</strong></p>
    <ol>
      <li parentName="ol">{`You'll have to return CORS headers that allow CodeSandbox to make these requests.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const response = {
  statusCode: 200,
  headers: {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "https://www.example.com",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  },
  body: JSON.stringify("Hello from Lambda!"),
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Make sure you add a secondary ID to your votes, primary for the item, secondary for the vote itself`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The uuid library is a great way to generate unique IDs`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use this sort of code to get a vote count`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const result = await db.scanItems({
  TableName: process.env.UPVOTES_TABLE!,
  FilterExpression: "#itemId = :itemId",
  ExpressionAttributeNames: {
    "#itemId": "itemId",
  },
  ExpressionAttributeValues: {
    ":itemId": itemId,
  },
})
`}</code></pre>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-7-solution"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-7-solution`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      